import { mapState, mapActions } from 'vuex'
import { formatDate } from '@/helpers'

export default {
  name: 'TFilesTableFinished',

  components: { TTableFiles: () => import('./table-files.vue') },

  data () {
    return {
      activeStatus: true,
      fileStatus: ['Finished', 2],
      thead: [
        { label: 'Data de Geração', name: 'generationDate' },
        { label: 'Forma de Pagamento', name: 'paymentMethod' },
        { label: 'Registros', name: 'registers' }
      ],
      totalPages: "0",
      currentPage: "0",
      pagesSelected: "0",
      total: 0,
      limit: 10
    }
  },

  props: {
    files: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      showBarStatus: ({ files }) => files.showBarStatus,
      fileListFinished: ({ files }) => files.fileListFinished,
      metaDataListFinished: ({ files }) => files.metaDataListFinished,
      currentYear: ({ files }) => files.currentYear,
      currentMonty: ({ files }) => files.currentMonty,
    }),

    filesList () {
      this.bindPlugin()
      return this.fileListFinished
    }
  },

  methods: {
    ...mapActions('files', ['getFilesFinished']),
    getHeadClass (headName, tableName) {
      if (headName === 'billings' || (headName === 'registers' && tableName !== 'importedReturns')) {
        return 'black-300'
      }

      if (headName === 'paymentMethod') {
        return 'column-100'
      }
    },

    solveColumnItem (item, key) {
      if (key === 'id' || key === 'bankCode' || key === 'walletId') {
        return 'hidden'
      }

      if (!isNaN(item) && key !== 'id') {
        return 'center aligned'
      }
    },

    bindPlugin () {
      Vue.nextTick().then(() => {
        $(`.extra-options .tui.ui.dropdown`).dropdown()
      })
    },

    prevPage () {
      if (this.currentPage === 1) return

      this.getFilesFinished(
        {
          year: this.currentYear,
          month: this.currentMonty,
          page: Number(this.currentPage) - 1,
          limit: this.limit
        }
      )
    },

    nextPage () {
      if (this.currentPage === this.totalPages) return

      this.getFilesFinished(
        {
          year: this.currentYear,
          month: this.currentMonty,
          page: Number(this.currentPage) + 1,
          limit: this.limit
        }
      )
    },

    goToPage (page) {
      if (Number(page) < 0 || Number(page) > Number(this.totalPages)) return
      this.currentPage = page
      this.getFilesFinished(
        {
          year: this.currentYear,
          month: this.currentMonty,
          page: Number(page),
          limit: this.limit
        }
      )
    },

    updateMetaDataTable () {
      if ( this.metaDataListFinished ) {
        this.totalPages = `${this.metaDataListFinished.totalPages}`
        this.currentPage = `${this.metaDataListFinished.currentPage}`
        this.pagesSelected = `${this.metaDataListFinished.limitValue}`
        this.total = this.metaDataListFinished.totalCount
      }
    },

    formatedData (data) {
      return {
        generationDate: formatDate(data.createdAt),
        paymentMethod: `${data.paymentMethodLabel}: ${data.bankName}`,
        registers: data.total,
        bankCode: data.bankCode,
        id: data.id
      }
    },

    bankName: data => data.bankName,

    generationDate: data => formatDate(data.createdAt)
  },

  mounted () {
    this.updateMetaDataTable()
  },

  updated () {
    this.updateMetaDataTable()
  }
}
